/* eslint-disable max-len */
/* eslint new-cap: ["error", { "newIsCap": false }] */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import Lodash from 'lodash';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { withStyles } from '@material-ui/core/styles';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Container, Grid, Button } from '@material-ui/core';
import { PlnDropdown } from '../../Components/Input';
import style from './styles';

import {
  useProvincias,
  getPuestosByProvince,
} from '../../Core/Api/locationApi';

const FRMInscripcionDiputados = props => {
  const { classes } = props;
  const { data } = useProvincias();

  const doc = new jsPDF('p', 'px', 'letter');

  const [showPreview, setShowPreview] = useState(false);

  // Opciones Seleccionadas
  const [sProvincia, setSProvincia] = useState(9);
  // eslint-disable-next-line no-unused-vars
  const [sPuesto, setSPuesto] = useState(999);

  // Lista de: Provincias, Puestos
  const [initialValues] = useState(
    Lodash.get(data, ['initValues'], {
      provincia: 9,
      puesto: 999,
    }),
  );

  const provincias = Lodash.get(
    data,
    ['frmOptions'],
    [
      {
        itemLabel: '- SELECCIONE LA PROVINCIA -',
        value: 9,
      },
    ],
  );

  const [puestos, setPuestos] = useState([
    { itemLabel: '- SELECCIONE EL PUESTO-', value: 999 },
  ]);

  const validationSchema = Yup.object({});

  const setProvincia = p => {
    setShowPreview(false);
    setPuestos([
      {
        itemLabel: '- SELECCIONE EL PUESTO-',
        value: 999,
      },
    ]);
    initialValues.provincia = p;
    initialValues.puesto = 999;
    if (p !== 9) {
      getPuestosByProvince(p).then(result => {
        if (result.status === 200) {
          const puestosList = Lodash.get(result, [
            'data',
            'response',
          ]);

          const clist = puestosList.map(
            ({ num_papeleta }) => {
              return {
                itemLabel: num_papeleta,
                value: num_papeleta,
              };
            },
          );
          clist.unshift({
            itemLabel: '- SELECCIONE EL PUESTO-',
            value: 999,
          });

          setSProvincia(
            Lodash.find(provincias, {
              value: p,
            }),
          );
          setPuestos(clist);
        }
      });
    }
  };

  const setPuesto = c => {
    setShowPreview(true);
    initialValues.provincia = sProvincia.value;
    initialValues.puesto = c;

    if (c !== 999) {
      setSPuesto(
        Lodash.find(puestos, {
          value: c,
        }),
      );
    }
  };

  const renderForm = () => {
    return (
      <div className={classes.boxForm}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <h4>Provincia</h4>
            <Field
              name="provincia"
              data={provincias}
              component={PlnDropdown}
              onChange={provincia =>
                setProvincia(provincia)
              }
            />
          </Grid>
          <Grid item xs={6}>
            <h4>Puesto</h4>
            <Field
              name="puesto"
              data={puestos}
              component={PlnDropdown}
              onChange={puesto => setPuesto(puesto)}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  const getTimeStamp = () => {
    const now = new Date();
    return `${
      now.getMonth() + 1
    }/${now.getDate()}/${now.getFullYear()} ${now.getHours()}:${
      now.getMinutes() < 10
        ? `0${now.getMinutes()}`
        : now.getMinutes()
    }:${
      now.getSeconds() < 10
        ? `0${now.getSeconds()}`
        : now.getSeconds()
    }`;
  };
  const centeredText = (text, y) => {
    const textWidth =
      (doc.getStringUnitWidth(text) *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const textOffset =
      (doc.internal.pageSize.width - textWidth) / 2;
    doc.text(textOffset, y, text);
  };

  const getNotesPDF = () => {
    let notesData = [];

    notesData = [
      [
        // eslint-disable-next-line max-len
        `El(la) suscrito (a) _______________________________________, con cédula de identidad número ____________________________, apercibido (a) de las penas con que la ley sanciona el delito de perjurio, DECLARO BAJO LA FE DE JURAMENTO lo siguiente:`,
      ],
      [
        // eslint-disable-next-line max-len
        ` `,
      ],
      [
        // eslint-disable-next-line max-len
        `1. Que cumplo con los requisitos que señala el artículo 14 del Estatuto del Partido para aspirar al cargo de Diputado (a) ante la Asamblea Legislativa de la República de Costa Rica.`,
      ],
      [
        // eslint-disable-next-line max-len
        `2. Que Acepto mi sometimiento incondicional al marco normativo, jurídico y ético que integralmente rige este proceso electoral interno, según el Estatuto del Partido, así como los reglamentos e instructivos del Tribunal de Elecciones Internas.`,
      ],
      [
        // eslint-disable-next-line max-len
        `3. Que reitero mi fe en los principios ideológicos y programáticos del Partido, sobre todo los contenidos en su Carta Fundamental y en su Estatuto.`,
      ],
      [
        // eslint-disable-next-line max-len
        `4. Que manifiesto mi absoluto respeto a las decisiones del Tribunal de Elecciones Internas, y en especial, a su veredicto final sobre los resultados de estas elecciones.`,
      ],
      [
        // eslint-disable-next-line max-len
        `5. Que no he sido sancionado (a) por el Tribunal de Ética y Disciplina del Partido o por los Tribunales Penales de la República, ni condenado por delito penal doloso, ni enfrentar actualmente proceso penal con auto de apertura a juicio y que tampoco me encuentro afectado (a) por los impedimentos que establece el artículo sétimo del Código Electoral para ser elegido(a) diputado(a), y que tampoco he sido inhabilitado (a) para el ejercicio de cargos públicos por Autoridad Administrativa competente según la Ley.(*)`,
      ],
      [
        // eslint-disable-next-line max-len
        `6. Que me comprometo expresa e irrevocablemente, en caso de ser elegido(a) diputado(a) a la Asamblea Legislativa, a contribuir económicamente con el Partido, con un tres por ciento de mis ingresos mensuales como legislador.`,
      ],
      [
        // eslint-disable-next-line max-len
        `Adjunto fotocopia por ambos lados de mi cédula de identidad al día y presento original de la misma para su confrontación.`,
      ],
      [
        // eslint-disable-next-line max-len
        `Anexo mi currículo vitae, y una fotografía tamaño pasaporte.`,
      ],
      [
        // eslint-disable-next-line max-len
        `Anexo hoja de información debidamente llena.`,
      ],
      [
        // eslint-disable-next-line max-len
        `(*) en caso de haber sido sancionado(a) por el Tribunal de Ética y Disciplina del Partido, condenado por delito penal doloso, o enfrentar proceso penal con auto de apertura a juicio, o proceso ético en el Partido, se debe indicar la causa o motivo que originó la sanción, condena o procesamiento:`,
      ],
      [
        // eslint-disable-next-line max-len
        `_________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________`,
      ],
      [
        // eslint-disable-next-line max-len
        ` `,
      ],
      [
        // eslint-disable-next-line max-len
        `FIRMA ____________________________________________ CÉDULA ________________________`,
      ],
    ];

    return notesData;
  };

  const generatePDF = () => {
    // ENCABEZADO DEL DOCUMENTO
    doc.setFont('times', 'bold');
    doc.text(
      20,
      40,
      process.env.REACT_APP_PDF_PARTIDO_REPORTS,
    );
    doc.setFontSize(12);
    doc.text(20, 60, 'Asamblea Nacional y Plenaria');
    doc.setFont('times', 'normal');
    doc.text(
      20,
      80,
      process.env.REACT_APP_PDF_PERIODO_REPORTS,
    );

    doc.setFont('times', 'bold');

    centeredText(
      'FORMULARIO DE INSCRIPCIÓN DE CANDIDATO(A) A DIPUTADO(A)',
      100,
    );

    doc.setFont('times', 'normal');
    doc.text(20, 120, 'Señores');
    doc.text(
      20,
      130,
      process.env.REACT_APP_PDF_TITLE_TRIBUNAL_REPORTS,
    );

    const paragraph = `Quien suscribe ______________________________________________________ cédula _________________, de conformidad con lo dispuesto en el Estatuto del Partido y el Reglamento para la elección de candidatos a diputado(a) para el período constitucional 2022-2026, solicito la inscripción de mi candidatura para participar en la elección correspondiente, con miras a las elecciones de diputados por la Provincia:`;
    const lines = doc.splitTextToSize(paragraph, 415);
    doc.text(20, 150, lines);

    const columns = ['Provincia', 'Puesto'];

    const typeTable1 = 'grid';
    const showTheHeader = true;

    const datos1 = [
      [sProvincia.itemLabel, sPuesto.itemLabel],
    ];

    // TABLA FORMULARIO
    doc.autoTable({
      head: [columns],
      body: datos1,
      theme: typeTable1,
      startY: 190,
      styles: {
        overflow: 'linebreak',
        cellWidth: 'wrap',
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'center',
          fillColor: [255, 255, 255],
        },
        1: {
          halign: 'center',
          fillColor: [255, 255, 255],
        },
        2: {
          halign: 'center',
          fillColor: [255, 255, 255],
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: showTheHeader,
      margin: { left: 20 },
    });

    // TABLA: CANDIDATOS DELEGADOS DISTRITALES
    doc.setFontSize(12);
    doc.setFont('times', 'normal');

    // TABLA: NOTAS
    const columnsAgrego = ['A'];
    const dataAgrego = [[`AGREGO:`]];

    doc.autoTable({
      head: [columnsAgrego],
      body: dataAgrego,
      theme: 'plain',
      startY: doc.lastAutoTable.finalY + 10,
      styles: {
        font: 'times',
        fontStyle: 'normal',
      },
      columnStyles: {
        0: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 12,
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'Left',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    const columnInfoAgrego = ['A'];
    const dataInfoAgrego = [
      [
        // eslint-disable-next-line max-len
        `* Original del comprobante, de haber cancelado la cuota de inscripción.`,
      ],
      [
        // eslint-disable-next-line max-len
        `* Copia del comprobante de estar al día con las contribuciones al Partido.`,
      ],
      [
        // eslint-disable-next-line max-len
        `* Firma del manifiesto, al momento de la inscripción`,
      ],
    ];

    doc.autoTable({
      head: [columnInfoAgrego],
      body: dataInfoAgrego,
      theme: 'plain',
      startY: doc.lastAutoTable.finalY,
      styles: {
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 12,
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 12,
        textColor: 0,
        halign: 'left',
      },
      showHead: 'never',
      margin: { left: 40 },
    });

    // TABLA: NOTAS
    const columnsT5 = ['A'];
    const dataT5 = [[`DECLARACIÓN JURADA`]];

    doc.autoTable({
      head: [columnsT5],
      body: dataT5,
      theme: 'plain',
      startY: doc.lastAutoTable.finalY + 10,
      styles: {
        font: 'times',
        fontStyle: 'bold',
      },
      columnStyles: {
        0: {
          halign: 'center',
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 12,
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'center',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    const columnsT6 = ['A'];
    const dataT6 = getNotesPDF();

    doc.autoTable({
      head: [columnsT6],
      body: dataT6,
      theme: 'plain',
      startY: doc.lastAutoTable.finalY,
      styles: {
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 12,
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 12,
        textColor: 0,
        halign: 'left',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    // FOOTER DOCUMENT
    const columnsPie = ['A'];
    const dataPie = [
      [
        // eslint-disable-next-line max-len
        `PLNDigital.com ${process.env.REACT_APP_PDF_YEAR_REPORTS} Email: consultas@plndigital.com PLN; Mata Redonda, 125m O. del edificio del M.A.G., Casa Liberacionista "José Figueres Ferrer" Tel: (506)2549­5500. Aptdo:10051­1000 San José. Sitio web desarrollado por Corporación DSI S.A.`,
      ],
    ];
    doc.autoTable({
      head: [columnsPie],
      body: dataPie,
      theme: 'plain',
      startY: doc.lastAutoTable.finalY + 50,
      styles: {
        font: 'times',
      },
      columnStyles: {
        0: {
          halign: 'left',
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 10,
        },
      },
      headStyles: {
        fillColor: [204, 204, 204],
        fontSize: 9,
        textColor: 0,
        halign: 'left',
      },
      showHead: 'never',
      margin: { left: 20 },
    });

    const fileTS = getTimeStamp();
    doc.save(
      `formulario-inscripcion-diputados-${fileTS}.pdf`,
    );
  };

  if (!Lodash.isEmpty(provincias)) {
    return (
      <Container
        maxWidth="lg"
        className={classes.container}
      >
        <h3>FORMULARIO DE INSCRIPCIÓN DE DIPUTADOS</h3>
        <h4 className={classes.subtitle}>
          Complete la siguiente información:
        </h4>
        <div className={classes.boxForm}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {renderForm()}
          </Formik>

          {showPreview ? (
            <div className={classes.boxDocToPdf}>
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  style={{ textAlign: 'left' }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={generatePDF}
                    style={{ marginTop: 10 }}
                  >
                    DESCARGAR FORMULARIO
                  </Button>
                </Grid>
              </Grid>
            </div>
          ) : (
            ''
          )}
        </div>
      </Container>
    );
  }
  return '';
};

FRMInscripcionDiputados.propTypes = {};

export default withStyles(style)(FRMInscripcionDiputados);
