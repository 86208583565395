/* eslint-disable max-len */
import RegistroGestores from '../Containers/RegistroGestores/RegistroGestores';
import Login from '../Containers/Login/Login';
import ForgotPassword from '../Containers/Login/ForgotPassword';
import MainPage from '../Containers/MainPage/MainPage';
import Dashboard from '../Containers/Dashboard/Dashboard';

// Registro Papeletas
import CantonesNoRepresentados from '../Containers/CantonesNoRepresentados/CantonesNoRepresentados';
import EditCantonesNoRepresentados from '../Containers/CantonesNoRepresentados/EditCantonesNoRepresentados';

// Registro Depositos
import RegistroDepositos from '../Containers/RegistroDepositos/RegistroDepositos';
import AddDeposito from '../Containers/RegistroDepositos/AddDeposito';
import EditDeposito from '../Containers/RegistroDepositos/EditDeposito';

// Consulta Debitos
import ConsultaDebitos from '../Containers/ConsultaDebitos/ConsultaDebitos';

// Formularios
import FRMInscripcionDiputados from '../Containers/Formulario/FRMInscripcionDiputados';
import FRMHojaInformacion from '../Containers/Formulario/FRMHojaInformacion';

const routesList = [
  {
    auth: false,
    component: MainPage,
    exact: true,
    name: 'mainPage',
    path: '/',
  },
  {
    auth: false,
    component: Login,
    exact: true,
    name: 'login',
    path: '/login',
  },
  {
    auth: false,
    component: ForgotPassword,
    exact: true,
    name: 'forgotPwd',
    path: '/forgot-password',
  },
  {
    auth: true,
    component: Dashboard,
    exact: true,
    name: 'dashboard',
    path: '/dashboard',
  },
  {
    auth: true,
    component: CantonesNoRepresentados,
    exact: true,
    name: 'cantones-no-representados',
    path: '/cantones-no-representados',
  },
  {
    auth: true,
    component: EditCantonesNoRepresentados,
    exact: true,
    name: 'editCantonesNoRepresentados',
    path: '/cantones-no-representados/edit/:id',
  },
  {
    auth: false,
    component: RegistroGestores,
    exact: true,
    name: 'registro-gestores',
    path: '/registro-gestores',
  },
  {
    auth: true,
    component: RegistroDepositos,
    exact: true,
    name: 'registroDepositos',
    path: '/registro-depositos',
  },
  {
    auth: true,
    component: AddDeposito,
    exact: true,
    name: 'addDeposito',
    path: '/registro-depositos/add',
  },
  {
    auth: true,
    component: EditDeposito,
    exact: true,
    name: 'editDeposito',
    path: '/registro-depositos/edit/:id',
  },
  {
    auth: true,
    component: ConsultaDebitos,
    exact: true,
    name: 'consultaDebitos',
    path: '/consulta-debitos',
  },
  {
    auth: false,
    component: FRMInscripcionDiputados,
    exact: true,
    name: 'frmInscripcionDiputados',
    path: '/formulario/inscripcion-diputados',
  },
  {
    auth: false,
    component: FRMHojaInformacion,
    exact: true,
    name: 'FRMHojaInformacion',
    path: '/formulario/hoja-informacion',
  },
];

export default routesList;
